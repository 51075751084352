import React from "react";

//style
import './Event.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";


function Event() {

    return (
        <div className="container-fluid">
            <div className="row">
                <Header />
                <div className="home ">
                    <div className="top align-items-center justify-content-center d-flex">
                        {/* <img src="/"></img>           */}
                        <div className="toptext p-3">
                            <h1 className="mt-1 ">
                                OUR UPCOMING
                            </h1>
                            <p className="mt-3">
                                EVENTS <span> 2024</span>
                            </p>
                        </div>
                    </div>
                    <div className="event p-3 align-items-center justify-content-center mt-5">
                        <div className="eventdetails d-flex flex-wrap  align-items-center justify-content-center ">
                            <div className="date">
                                <p className="mt-3">Dec 27</p>
                            </div>
                            <div className="details  p-2">
                                <p className="mt-3">
                                <span> <strong>Theme: </strong></span>    DesignCave at One: Honoring the Journey, Inspiring the Future. </p>
                                <p className="mt-3">
                                <span> <strong>Guest Speaker: </strong></span>   DesignCave Co-Founder and other members </p>
                            </div>
                        </div>
                        <div className="horizontal-line w-50 d-flex align-items-center justify-content-center"></div>
                    </div>

                    <h1 className="d-flex align-items-center justify-content-center mt-5">2025</h1>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                        <div className="eventdetails2 d-flex flex-wrap  align-items-center justify-content-center mb-2 ">
                            <div className="details p-2">
                            <p className="mt-3 ">
                            <span> <strong>Program </strong></span>  Design Talk Day. </p>
                                <p className="mt-3 ">
                                <span> <strong>Theme: </strong></span>  Fresh Perspectives: Starting 2025 with Design in Focus. </p>
                                <p className="mt-3">
                                <span> <strong>Insight:  </strong></span>   The focus is on helping the audience think creatively, solve problems, and approach design with fresh energy for the year ahead.</p>
                                <p className="mt-3">
                                <span> <strong>Guest Speaker: </strong></span>  Name will be updated. </p>
                            </div>
                            <div className="date2">
                                <p className="mt-3">JAN 31</p>
                            </div>
                        </div>
                        <div className="horizontal"></div>

                        <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2">
                        <div className="date">
                                <p className="mt-3">Feb 28</p>
                            </div>
                            <div className="details p-2 ">
                            <p className="mt-3 ">
                            <span> <strong>Program </strong></span>  Design Talk Day. </p>
                                <p className="mt-3 ">
                                <span> <strong>Theme: </strong></span>  Designing for Impact, Beyond Aesthetics. </p>
                                <p className="mt-3 ">
                                <span> <strong>Insight: </strong></span> This session will focus on how design goes beyond looking good—it’s about solving real problems and creating experiences that make people’s lives better. We’ll discuss how designers can create work that’s both beautiful and impactful.</p>
                                <p className="mt-3">
                                  <span> <strong>Guest Speaker:</strong></span>   Name will be updated. </p>
                            </div>
                            <div className="horizontal"></div>
                        </div>
                    </div>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                    <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2">
                    <div className="details p-2">
                        <p className="mt-3">
                        <span><strong>Program:</strong></span> Enlightenment Session (Free 4-Week Program).
                        </p>
                        <p className="mt-3">
                        <span><strong>Theme:</strong></span> Fundamentals of UI/UX Design.
                        </p>
                        <p className="mt-3">
                        <span><strong>Insight:</strong></span> Dive into the foundations of design thinking, exploring the basics of user interfaces and user experiences. Learn how to craft intuitive designs that meet users' needs and set the stage for your design journey.
                        </p>
                        <p className="mt-3">
                        <span><strong>Tutor:</strong></span> Experienced Designer.
                        </p>
                    </div>
                    <div className="date2">
                        <p className="mt-3">FEB 18 - MAR 17</p>
                    </div>
                    </div>

                    <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2">
                    <div className="date">
                        <p className="mt-3">MAR 14</p>
                    </div>
                    <div className="details p-2">
                        <p className="mt-3">
                        <span><strong>Program:</strong></span> 
                        (Bi-Monthly Event).
                        </p>
                        <p className="mt-3">
                        <span><strong>Theme:</strong></span> TBA(To be announced)
                        </p>
                        <p className="mt-3">
                        <span><strong>Insight:</strong></span>TBU (To be uodated)
                        </p>
                        <p className="mt-3">
                        <span><strong>Guest Speaker:</strong></span> To Be Announced.
                        </p>
                    </div>
                    </div>

                    </div>

                    <div className="event d-flex flex-wrap align-items-center justify-content-between mt-5">
                        <div className="eventdetails2 d-flex flex-wrap  align-items-center justify-content-center mb-2 ">
                            <div className="details p-2">
                            <p className="mt-3 ">
                            <span> <strong>Program </strong></span>  Design Talk Day. </p>
                                <p className="mt-3 ">
                                <span> <strong>Theme: </strong></span>   The Power of User Research in Design</p>
                                <p className="mt-3">
                                <span> <strong>Insight:   </strong></span>   Understanding your users is the foundation of good design. This talk will cover why user research matters, how to gather insights, and how those insights guide your design decisions to meet users’ needs effectively.
                                    </p>
                                <p className="mt-3">
                                <span> <strong>Guest Speaker: </strong></span> Name will be updated. </p>
                            </div>
                            <div className="date2">
                                <p className="mt-3">MAR 28</p>
                            </div>
                        </div>
                        <div className="horizontal"></div>

                        <div className="eventdetails2 d-flex flex-wrap align-items-center justify-content-center mb-2 ">
                        <div className="date ">
                                <p className="mt-3">APR 25</p>
                            </div>
                            <div className="details p-2 ">
                            <p className="mt-3 ">
                            <span> <strong>Program </strong></span>  Design Talk Day. </p>
                                <p className="mt-3 ">
                                <span> <strong>Theme: </strong></span>  The Art of Prototyping: Testing Your Ideas</p>
                                <p className="mt-3 ">
                                <span> <strong>Insight:</strong></span>    Prototyping helps you bring your ideas to life and test them before full development. We’ll dive into simple steps for creating prototypes and why they’re crucial for getting feedback early in the design process.
                                    </p>
                                <p className="mt-3">
                                <span> <strong>Guest Speaker: </strong></span>Name will be updated. </p>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
            <script async src="https://eocampaign1.com/form/b8ab6c36-bc82-11ef-89f5-85263dcbd4b2.js" data-form="b8ab6c36-bc82-11ef-89f5-85263dcbd4b2"></script>
        </div>
    );
}

export default Event